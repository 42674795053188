import(/* webpackMode: "eager", webpackExports: ["AuthKitProvider"] */ "/app/node_modules/@workos-inc/authkit-nextjs/dist/esm/authkit-provider.js");
;
import(/* webpackMode: "eager", webpackExports: ["MinMaxButton"] */ "/app/node_modules/@workos-inc/authkit-nextjs/dist/esm/min-max-button.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/IttybitRegular.woff2\",\"display\":\"swap\",\"variable\":\"--font-ittybit-regular\",\"weight\":\"400\",\"style\":\"normal\"}],\"variableName\":\"ittybitRegular\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/IttybitBold.woff2\",\"display\":\"swap\",\"variable\":\"--font-ittybit-bold\",\"weight\":\"900\",\"style\":\"normal\"}],\"variableName\":\"ittybitBold\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/IttybitNarrow.woff2\",\"display\":\"swap\",\"variable\":\"--font-ittybit-narrow\",\"weight\":\"400\",\"style\":\"normal\"}],\"variableName\":\"ittybitNarrow\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/IttybitMono.woff2\",\"display\":\"swap\",\"variable\":\"--font-ittybit-mono\",\"weight\":\"normal\",\"style\":\"normal\"}],\"variableName\":\"ittybitMono\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-inter\",\"display\":\"swap\",\"src\":[{\"path\":\"../fonts/InterVariable-Italic.woff2\",\"style\":\"italic\"},{\"path\":\"../fonts/InterVariable.woff2\",\"style\":\"normal\"}]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"Inconsolata\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-inconsolata\"}],\"variableName\":\"inconsolata\"}");
;
import(/* webpackMode: "eager", webpackExports: ["PostHogProvider"] */ "/app/src/components/PostHogProvider.js");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.css");
